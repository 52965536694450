import { Asset } from './asset'
import { AlternateHreflang } from './seo'

export interface Brand {
  id: string
  name: string
  blogPrefix: string
  logo: Asset
  whiteLogo: Asset
  productionUrl: string
  favicon: Asset | null
  facebook?: string
  twitter?: string
  linkedIn?: string
  youTube?: string
  instagram?: string
  reddit?: string
  flagshipProduct?: string
  phoneNumber: string
  alternateName: string[]
  parentOrganization: string
  isTopResume: boolean
  isTopCV: boolean
  isTopCVuk: boolean
  isZipJob: boolean
  isTopCvFr: boolean
  isTopCvDe: boolean
  alternateHreflang: AlternateHreflang[] | null
  rssFeedTitle: string | null
  rssFeedHref: string | null
  brandName: string
}

export interface DynamicText {
  contentful_id: string
  __typename: 'ContentfulDynamicText'
  textVariable:
    | 'brand.flagshipProduct'
    | 'brand.name'
    | 'brand.phoneNumber'
    | 'brand.productionUrl'
}

export enum BrandNames {
  topResume = 'topresume',
  topCv = 'topcv',
  topCvUk = 'topcv.co.uk',
  zipJob = 'zipjob',
  TopCvFr = 'topcv.fr',
  TopCvDe = 'topcv.de',
}
